// store.js or index.js where you configure your store
import { configureStore } from '@reduxjs/toolkit';
import authReducer from './ReduxSlices/UserSlice';
import userReducer from "./ReduxSlices/ProfileSlice.jsx";
import creditReducer from './ReduxSlices/creditSlice.jsx';

const store = configureStore({
  reducer: {
    auth: authReducer,
    user: userReducer,
    credits: creditReducer,
  },
});

export default store;
