import React from 'react';
import './../styles/defaultPageComponent.css';

function DefaultPageComponent() {
    return (
        <div className="coming-soon-container">
            <img
                src="https://images.unsplash.com/photo-1614332287897-cdc485fa562d?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                alt="Coming Soon"
                className="animated-image"
            />
            <h1 className="coming-soon-title">Coming Soon</h1>
        </div>
    );
}

export default DefaultPageComponent;
