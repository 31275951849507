
import React, { useState } from 'react';
import axios from 'axios';
import SongList from './SongList';
import btnBackground from "../../assets/dashboard/create-button-background.svg";
import Loader from "../../components/Loader";
import { FaInfoCircle } from 'react-icons/fa';
import HowToUsePlatform from '../../components/HowToUsePlatform';
import {
  deductCreditBalance,
  fetchCreditBalance,
} from "../../redux/ReduxSlices/creditSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";

export default function GetStartedPage() {
  const [prompt, setPrompt] = useState('');
  const [makeInstrumental, setMakeInstrumental] = useState(false);
  const [waitAudio, setWaitAudio] = useState(true);
  const [responseMessage, setResponseMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState("");
  const [songs, setSongs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showGeneratedSongs, setShowGeneratedSongs] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false); // Corrected initial value
  const { balance, status } = useSelector((state) => state.credits);
  const dispatch = useDispatch();

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   const payload = {
  //     prompt,
  //     make_instrumental: makeInstrumental,
  //     wait_audio: waitAudio,
  //   };

  //   setLoading(true);
  //   setResponseMessage('');
  //   setSongs([]);
  //   setShowGeneratedSongs(false);

  //   try {
  //     // const response = await axios.post('https://musicapi.imadeasong.com/api/generate', payload);

  //     const response = await axios.post('/api/generate', payload, {
  //       withCredentials: true,
  //       credentials: 'include',
  //     });

  //     console.log('Response is Here::');
  //     console.log(response);

  //     setResponseMessage(response.data.message || 'Song generated successfully!');
  //     setSongs(response.data || []);
  //     setShowGeneratedSongs(true);
  //   } catch (error) {
  //     setResponseMessage('Failed to generate song. Please try again.');
  //     setSongs([]);
  //     setShowGeneratedSongs(false);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (balance.credit_point <= 9) {
      return toast.error("You have insufficient credits!");
    }

    if (!prompt.trim()) {
      setErrorMessage("Please enter a prompt to generate a song.");
      return;
    }

    setErrorMessage("");

    const payload = {
      prompt,
      make_instrumental: makeInstrumental,
      wait_audio: waitAudio,
    };

    setLoading(true);
    setResponseMessage("");
    setSongs([]);
    setShowGeneratedSongs(false);

    try {

      // 1 - Uncomment this while Production - 
      const MUSIC_API = 'https://musicapi.imadeasong.com';
      const response = await axios.post(`${MUSIC_API}/api/generate`, payload, {

        // 2 - Uncomment this while Local - 
        // const response = await axios.post("/api/generate", payload, {
        withCredentials: true,
        credentials: "include",
      });

      setResponseMessage(
        response.data.message || "Song generated successfully!"
      );
      setSongs(response.data || []);
      setShowGeneratedSongs(true);

      // Deduct 5 credits and refresh balance after a successful response
      await dispatch(deductCreditBalance(10));
      dispatch(fetchCreditBalance());
    } catch (error) {
      setResponseMessage(`Failed to generate song - ${error.response.data.error}`);

      setSongs([]);
      setShowGeneratedSongs(false);
    } finally {
      setLoading(false);
    }
  };


  const handleInfoClick = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="min-h-screen p-6 main-content mt-10">
      <div className={`grid grid-cols-1 ${showGeneratedSongs ? 'md:grid-cols-2 gap-4' : ''}`}>


        <div className=''>
          <div className="p-6 mt-8 rounded-lg shadow-md border-2 border-[#344e4d] w-4/5">
            <div className='flex relative'>
              <h2 className="text-2xl font-semibold text-white text-center mb-6">
                🎵 Create a melody 🎵
              </h2>
              <FaInfoCircle
                onClick={handleInfoClick}
                className="text-white cursor-pointer hover:text-blue-500 absolute right-4"
                size={24}
              />
            </div>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label htmlFor="prompt" className="block text-white font-medium mb-2">
                  Enter Prompt
                </label>
                <textarea
                  id="prompt"
                  rows="8"
                  value={prompt}
                  onChange={(e) => setPrompt(e.target.value)}
                  placeholder="Write your song..."
                  className="w-full px-4 py-2 text-black border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                ></textarea>
              </div>

              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="makeInstrumental"
                  checked={makeInstrumental}
                  onChange={(e) => setMakeInstrumental(e.target.checked)}
                  className="mr-2"
                />
                <label htmlFor="makeInstrumental" className="text-white">
                  Make Instrumental
                </label>
              </div>

              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="waitAudio"
                  checked={waitAudio}
                  onChange={(e) => setWaitAudio(e.target.checked)}
                  className="mr-2"
                />
                <label htmlFor="waitAudio" className="text-white">
                  Wait for Audio
                </label>
              </div>

              <button
                type="submit"

                className="w-full bg-blue-500 hover:bg-blue-600 text-white font-semibold p-4 rounded-full transition-colors button-bg-color"
              >
                {loading ? 'Generating...' : 'Generate'}
              </button>
            </form>

            {responseMessage && (
              <div className="mt-4  text-xl text-center text-white">
                {responseMessage}
              </div>
            )}
          </div>
        </div>

        {/* Generated songs section */}
        {loading ? (
          <div className="w-full p-6 rounded-lg shadow-md">
            <Loader />
          </div>
        ) : showGeneratedSongs && (
          <div className="w-full p-6 rounded-lg shadow-md">
            <h2 className="text-3xl font-semibold text-center text-white mb-8">Generated Songs</h2>
            {songs.length > 0 ? (
              <SongList songs={songs} />
            ) : (
              <div className="text-center text-white">No songs generated yet.</div>
            )}
          </div>
        )}

      </div>

      {/* Modal for HowToUsePlatform */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-700 bg-opacity-60 z-50 flex justify-center items-center text-black shadow-lg">
          <div className="rounded-lg p-6 w-1/2 overflow-auto">
            <HowToUsePlatform onClose={closeModal} />
          </div>
        </div>
      )}
    </div>
  );
}
