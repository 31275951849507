import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import apiUrl from "../../services/api";


export const UserProfileDetails = createAsyncThunk(
  "profile/get_user_details",
  async (_, { rejectWithValue }) => {

    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(apiUrl.getUserDetails, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      console.log("user deatils", response.data);

      var userName = response.data.first_name;

      localStorage.setItem('userName', userName);

      return response.data;

    } catch (error) {
      console.error("Error fetching captcha:", error.message);

      if (!error.response) {
        console.error("Network error or server is unreachable");
        return rejectWithValue("Network error or server is unreachable");
      }

      const navigate = useNavigate();

      navigate("/signin");

      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  userDetails: null,
  loading: false,
  error: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(UserProfileDetails.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(UserProfileDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.userDetails = action.payload;
    });

    builder.addCase(UserProfileDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default userSlice.reducer;
