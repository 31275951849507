import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { loginUser } from "../../redux/ReduxSlices/UserSlice";
import "../../styles/signup.css";
import logoVideo from "../../assets/gif/brandImg.mp4";

function LoginPage() {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogin = async () => {
    try {
      const result = await dispatch(loginUser({ userName, password })).unwrap();

      localStorage.setItem("token", result.token);

      navigate("/dashboard");

      setTimeout(() => {
        window.location.reload();
      }, 1200);
    } catch (error) {
      console.log(error);
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      console.log("enter")
      handleLogin(); // Trigger login on Enter key press
    }
  };

  return (
    <div>
      <div className="w-full min-h-screen text-gray-900 flex relative top-8 bg-black">
        <div className="m-0 sm:rounded-lg flex justify-center flex-1">
          <div className="flex-1 text-center hidden lg:flex justify-center">
            <div className="w-3/4 bg-contain rounded flex items-center flex-col justify-center">
              <div className="mb-6">
                <video
                  className="object-cover rounded-lg"
                  autoPlay
                  playsInline
                  loop
                  muted
                >
                  <source src={logoVideo} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
          <div className="lg:w-1/2 w-full flex items-center justify-center right-side-bg">
            <div className="flex mt-5 flex-col items-center justify-center w-1/2">
              <h1 className="text-3xl xl:text-3xl font-extrabold text-black">
                Sign In
              </h1>
              <div className="w-full flex-1 mt-8">
                <div className="flex flex-col items-center">
                  <div className="w-full">
                    <input
                      className="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
                      type="text"
                      placeholder="User Name"
                      value={userName}
                      onChange={(e) => setUserName(e.target.value)}

                    />
                    <div className="relative w-full mt-5">
                      <input
                        className="w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
                        type={showPassword ? "text" : "password"}
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <button
                        type="button"
                        className="eye-button absolute inset-y-0 right-0 px-3 flex items-center text-gray-500 bg-grey"
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                      </button>
                    </div>
                    <button
                      className="mt-5 tracking-wide font-semibold button-bg-color text-gray-100 w-full py-4 rounded-lg hover:bg-indigo-700 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none"
                      onClick={handleLogin}
                      onKeyDown={handleKeyDown}
                    >
                      <span className="ml-3">Continue</span>
                    </button>
                  </div>
                </div>

                {/* <div className="leading px-2 py-4 text-right text-sm text-gray-600 font-medium">
                  <p className="text-gray-400">Forget Password?</p>
                </div> */}
                <hr className="mt-6" />
                <div className="mt-6 w-full text-center"></div>
                <div className="leading px-2 py-4 text-center text-sm text-gray-600 font-medium">
                  <p className="items-center text-gray-400">
                    Don't have an account?{" "}
                    <span
                      className="underline hover:text-blue-500 cursor-pointer"
                      onClick={() => navigate("/signup")}
                    >
                      {" "}
                      Sign Up{" "}
                    </span>
                  </p>
                </div>
                <div className="text-wrap">
                  <p className="mt-6 text-m text-gray-600 text-center text-wrap">
                    By clicking "Continue" you agree
                    to our
                    <br />
                    <Link
                      className="border-b border-blue-800 mr-2 text-blue-800"
                      to={"/terms-of-service"}
                    >
                      Terms of Service
                    </Link>
                    and
                    <Link
                      className="border-b text-blue-800 border-blue-800 ml-2"
                      to={"/privacy-policy"}
                    >
                      Privacy Policy
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
