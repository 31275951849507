// creditSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import apiUrl from '../../services/api';

// Async thunk for getting credit balance
export const fetchCreditBalance = createAsyncThunk(
  'credits/fetchCreditBalance',
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(apiUrl.creditBalance, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response.data, "respnse credit")
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Error fetching credit balance');
    }
  }
);

// Async thunk for deducting credit balance
export const deductCreditBalance = createAsyncThunk(
  'credits/deductCreditBalance',
  async (creditPoint, { rejectWithValue }) => {
    try {
      const username = localStorage.getItem('userName');
      const token = localStorage.getItem('token');

      const response = await axios.post(
        apiUrl.deductCredit,
        {
          username,
          credit_point: creditPoint,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return creditPoint;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Error deducting credit balance');
    }
  }
);

// Slice for managing credit balance state
const creditSlice = createSlice({
  name: 'credits',
  initialState: {
    balance: [],
    status: 'idle',
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCreditBalance.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCreditBalance.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.balance = action.payload; // Adjust this based on your response structure
      })
      .addCase(fetchCreditBalance.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(deductCreditBalance.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deductCreditBalance.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.balance -= action.payload; // Update balance if necessary
      })
      .addCase(deductCreditBalance.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  }
});

export default creditSlice.reducer;
