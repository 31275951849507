import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  AiFillPauseCircle,
  AiFillPlayCircle,
  AiOutlineLike,
  AiOutlineDislike,
} from "react-icons/ai";
import LoaderTwo from "../../components/LoaderTwo";

import { saveAs } from "file-saver";

const GetAllVoice = () => {
  const [voices, setVoices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentAudio, setCurrentAudio] = useState(null);
  const [playingVoiceId, setPlayingVoiceId] = useState(null);
  const [duration, setDuration] = useState(0);
  console.log(duration, "duration");
  const [currentTime, setCurrentTime] = useState(0);
  const [showLyricsId, setShowLyricsId] = useState(null);

  useEffect(() => {
    const fetchVoices = async () => {
      try {
        // 1 - Uncomment this while Production - 
        const MUSIC_API = 'https://musicapi.imadeasong.com';
        const response = await axios.get(`${MUSIC_API}/api/get`, {

          // 2 - Uncomment this while Local - 
          // const response = await axios.get("/api/get", {
          method: "GET",
          credentials: "include",
        });

        if (response.status === 200) {
          setVoices(response.data);
        } else {
          console.error("Error fetching data:", response.statusText);
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchVoices();
  }, []);
  const handleImageClick = (id) => {
    setShowLyricsId((prevId) => (prevId === id ? null : id)); // Toggle lyrics display
  };
  const handlePlayPause = (voice) => {
    if (currentAudio && currentAudio.src !== voice.audio_url) {
      currentAudio.pause();
      currentAudio.currentTime = 0;
      setCurrentAudio(null);
      setPlayingVoiceId(null);
    }

    if (currentAudio && currentAudio.src === voice.audio_url) {
      if (currentAudio.paused) {
        currentAudio.play();
        setPlayingVoiceId(voice.id);
      } else {
        currentAudio.pause();
        setPlayingVoiceId(null);
      }
    } else {
      const audio = new Audio(voice.audio_url);
      audio.play();
      audio.onloadedmetadata = () => {
        setDuration(audio.duration);
      };
      audio.ontimeupdate = () => {
        setCurrentTime(audio.currentTime);
      };
      audio.onended = () => {
        setPlayingVoiceId(null);
      };

      setCurrentAudio(audio);
      setPlayingVoiceId(voice.id);
    }
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60)
      .toString()
      .padStart(2, "0");
    return `${minutes}:${seconds}`;
  };

  // const handleDownload = (audioUrl, title) => {
  //   // Create an anchor element
  //   const link = audioUrl; // Set the URL of the audio file
  //   const download = title; // Extracts the file name (or set a custom name)

  //   saveAs(link, download);
  // };

  if (loading) {
    return (
      <div className="flex justify-center items-center">
        <LoaderTwo />
      </div>
    );
  } else {
    return (
      <div className="main-content">
        <div className="text-4xl text-start pl-4 font-bold mt-6">
          Your Collection
        </div>
        <div className="justify-center p-3 gap-16 mt-4">
          {voices.map((voice) => (
            <div
              key={voice.id}
              onClick={() => handleImageClick(voice.id)}
              className="shadow-lg p-2 border-2 border-[#344e4d] rounded-lg mb-6 "
            >
              <div
                className="flex gap-4"

              >
                {/* Image Section */}
                <img
                  src={voice.image_url}
                  alt={voice.title}
                  className="w-16 h-20 object-cover rounded-lg cursor-pointer"
                // Toggles lyrics display
                />

                <div className="flex flex-col flex-grow">
                  <h2 className="text-xl  mt-2 font-semibold text-white">
                    {voice.title}
                  </h2>
                  <p className=" mt-2 text-white"> {voice.tags}</p>
                </div>

                <div className="flex  items-center justify-between gap-4">
                  <div className="flex gap-4 ">
                    <AiOutlineLike className="text-2xl text-green-500 cursor-pointer" />
                    <AiOutlineDislike className="text-2xl text-red-500 cursor-pointer" />
                  </div>
                  <button
                    onClick={() => handlePlayPause(voice)}
                    className="text-blue-500 mx-5"
                  >
                    {playingVoiceId === voice.id ? (
                      <AiFillPauseCircle className="text-3xl fill-white" />
                    ) : (
                      <AiFillPlayCircle className="text-3xl fill-white" />
                    )}
                  </button>
                </div>
              </div>
              <div className="absolute right-40">
                {playingVoiceId === voice.id && (
                  <div className=" ">
                    <div className=" ">
                      <progress
                        value={currentTime}
                        max={duration}
                        className="w-42 h-2  bg-[#344e4d] rounded-full overflow-hidden shadow-md"
                      ></progress>
                      <p className="text-gray-300 text-center  mt-1">
                        {`${formatTime(currentTime)} / ${formatTime(duration)}`}
                      </p>
                    </div>
                  </div>
                )}
              </div>
              <div className="">
                {showLyricsId === voice.id && (
                  <p className="text-sm text-gray-400 mt-16">{voice.lyric}</p>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
};

export default GetAllVoice;
