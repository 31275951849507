import React from "react";

function AboutUs() {
  return (
    <>
      <div className="bg-white min-h-screen px-4 py-12">
        <div className="max-w-6xl mx-auto mt-20">
          <div className="text-center">
            <h1 className="text-4xl md:text-6xl font-bold text-center text-gray-800 mb-12">
              About Us
            </h1>
            <p className="text-lg text-gray-700 mb-8 font-bold">
              Discover the story of Digikore Studios Private Limited and our
              revolutionary approach to AI-driven music creation.
            </p>
          </div>

          <div>
            <p className="text-lg text-gray-700 mb-8">
              I Made a Song is owned and operated by Digikore Studios Private Limited. At
              Digikore Studios, we are redefining the future of music with
              cutting-edge technology and innovation. As pioneers in AI-driven
              music solutions, we are building the world’s first-of-its-kind AI
              music platform, where artistry meets artificial intelligence to
              create transformative musical experiences.
            </p>

            <p className="text-lg text-gray-700 mb-8">
              With a foundation in advanced technology, Digikore Studios
              combines deep expertise in artificial intelligence, machine
              learning, and sound engineering to push the boundaries of what
              music can be. Our team of seasoned engineers, musicians, and AI
              experts work collaboratively to design a platform that empowers
              creators, artists, and listeners to explore new realms of musical
              creativity.
            </p>

            <p className="text-lg text-gray-700 mb-8">
              We understand the profound impact music has on people, and our
              platform is engineered to make music creation accessible, dynamic,
              and endlessly innovative. Whether you are an artist seeking unique
              compositions or a listener craving immersive experiences, Digikore
              Studios is here to elevate your journey with state-of-the-art AI
              technology.
            </p>

            <p className="text-lg text-gray-700 mb-8">
              At Digikore, our commitment is not only to stay at the forefront
              of AI in music but to pioneer a new era where technology and
              creativity harmonize seamlessly. Join us as we shape the future of
              sound, setting a new standard in the world of digital music.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutUs;
