import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserProfileDetails } from "../../redux/ReduxSlices/ProfileSlice";
import "./../../styles/dashboardPage.css";
import { fetchCreditBalance } from "../../redux/ReduxSlices/creditSlice";
import { useNavigate } from "react-router-dom";

function Sidebar({ setActiveContent, activeContent }) {
  const dispatch = useDispatch();

  const { balance, status } = useSelector((state) => state.credits);
  const { userDetails, loading, error } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(UserProfileDetails());
    dispatch(fetchCreditBalance());
  }, [dispatch]);

  const avatarLetter = userDetails?.first_name
    ? userDetails.first_name.charAt(0).toUpperCase()
    : "U";

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.detail || error.toString()}</div>;
  }

  return (
    <div className="sidebar">
      <div className="logo bg-gradient-to-r from-green-500 via-teal-500 to-pink-500 text-transparent bg-clip-text">
        I MADE A SONG
      </div>

      <div className="user-info">
        <div className="avatar"> {avatarLetter}</div>
        <div className="email">
          {userDetails?.first_name} {userDetails?.last_name}
          <p className="text-xs text-center">{userDetails?.email}</p>
        </div>
      </div>

      <ul className="menu cursor-pointer">
        <li
          onClick={() => setActiveContent("create")}
          className={`px-1 py-1 ${activeContent === "create"
            ? "bg-gray-500 text-white rounded-lg px-2"
            : ""
            }`}
        >
          Create
        </li>
        <li
          onClick={() => setActiveContent("library")}
          className={`px-2 py-1 ${activeContent === "library"
            ? "bg-gray-700 text-white rounded-lg"
            : ""
            }`}
        >
          Library
        </li>
      </ul>
      <div className="credits">
        <span>
          {balance.credit_point !== null
            ? balance.credit_point < 0
              ? "0 credits"
              : `${balance.credit_point} credits`
            : "Loading credits..."}
        </span>
        <button className="upgrade-btn" onClick={() => setActiveContent("upgrade")}>
          Upgrade
        </button>
      </div>
    </div>
  );
}

export default Sidebar;
