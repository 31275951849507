import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import loader from './assets/dashboard/loader.gif';
import Navbar from './components/Header';
import HomePage from './pages/Home/HomePage';
import LoginPage from './pages/Login/LoginPage';
import SignUpPage from './pages/Login/SignUpPage';
import Footer from './components/Footer';
import UserProfilePage from './pages/Profile/UserProfilePage';
import DefaultPageComponent from './components/DefaultPageComponent';
import UpgradePlan from './pages/upgrade_Plans/UpgradePlan';
import Cancellation from "./pages/TermAndCondition/Cancellation";
import PrivacyPolicy from './pages/TermAndCondition/PrivacyPolicy';
import GetStartedPage from './pages/VoiceCreation/GetStartedPage';
import GetAllVoice from './pages/VoiceCreation/GetAllVoice';
import SongList from './pages/VoiceCreation/SongList';
import DashboardPage from './pages/Dashboard/DashboardPage';
import TermsOfService from './pages/TermAndCondition/TermsOfService';
import AboutUs from './pages/TermAndCondition/AboutUs';
import ProtectedRoute from './layout/ProtectedRoute';


const App = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {

    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return (
      <div className='flex items-center justify-center h-screen text-white'>
        <img src={loader} alt="loader" />
      </div>
    );
  }

  return (

    <>
      <Router>
        <Navbar />
        : (
        <Routes>
          {/* Index page  */}
          <Route path="/" element={<><HomePage />  <Footer /> </>} />
          {/* Authentication  */}
          <Route path="/signup" element={<SignUpPage />} />
          <Route path="/signin" element={<LoginPage />} />

          <Route path="/get-started" element={<ProtectedRoute><GetStartedPage /></ProtectedRoute>} />
          <Route path="/all-voices" element={<ProtectedRoute><GetAllVoice /></ProtectedRoute>} />
          <Route path="/song-list" element={<ProtectedRoute><SongList /></ProtectedRoute>} />

          {/* User Profile  */}
          <Route path="/user-profile" element={<UserProfilePage />} />

          {/* Default page  */}
          <Route path="/default-page" element={<> <DefaultPageComponent /> <Footer /></>} />

          {/* Main Dashboard */}
          <Route path="/dashboard" element={<ProtectedRoute><DashboardPage /></ProtectedRoute>} />

          {/* Subscription */}
          <Route path="upgrade-plan" element={<ProtectedRoute><UpgradePlan /></ProtectedRoute>} />

          {/* Privacy policy */}
          <Route path='/cancellation-policy' element={<> <Cancellation />  <Footer /> </>} />
          <Route path="/privacy-policy" element={<> <PrivacyPolicy /> <Footer /> </>} />
          <Route path="/terms-of-service" element={<><TermsOfService /> <Footer /></>} />
          <Route path="/about-us" element={<><AboutUs /> <Footer /></>} />

          {/* FAQ */}
          {/* <Route path="/faq" element={<FAQAccordion/>}/> */}
        </Routes>
        )
      </Router>
    </>
  );
};

export default App;
