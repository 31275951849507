import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import "../../styles/homePage.css";
import GridCard from '../../components/GridCardComponet';
import MusicImage from '../../assets/dashboard/music.png';
import { toast } from "react-toastify";
import DashboardSubscription from '../../components/DashboardSubscription';

const HomePage = () => {
  const navigate = useNavigate();

  const handleGenrate = () => {
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/dashboard');
    } else {

      toast.info("Please sign in to continue.", { autoClose: 1000 });

      setTimeout(() => {
        navigate("/signin");
      }, 1000);
    }

  }
  return (
    <div className="homepage-container">
      {/* <div className="badge">#1 Product of the Day</div> */}
      <h1 className="headline">Create Your Own Song with Ease</h1>
      <p className="description">
        Turn your words into music—no experience needed. Just type, tune, and share your song with the world!
      </p>
      <button className="cta-button mt-8" onClick={handleGenrate}>
      🎵 Create a melody 🎵
      </button>
      <div className="text-center">
        <img src={MusicImage} alt="music-image" className="mx-auto" />
      </div>

      <div className="margin-xl">
        <h1 className="headline">Generate high-performing songs with a single click</h1>
        <p className="margin-large description">
          Simply enter your prompt,<br></br>Sit back and our AI will do the rest.
          Don't let limit your creativity hold you back from innovation.
        </p>
      </div>
      {/* <div className="bg-white padding-large">
        <h1 className="headline-black">Generate more songs with less effort to boost creativity and impact.</h1>
        <p className="margin-large description-black ">
          I made a song AI helps you create unlimited song variations to explore and discover the ones that resonate best with your listeners,<br />amplifying your reach and impact.
        </p>
        <div className="card-alignment">
          <div className="part">
            <h2 className="count-highlighed">2.7x</h2>
            <Link className="sub-text" to='#\'>More leads*</Link>
          </div>
          <div className="divider"></div>
          <div className="part">
            <h2 className="count-highlighed">1.7x</h2>
            <Link className="sub-text" to='#/'>Higher ROI*</Link>
          </div>
          <div className="divider"></div>
          <div className="part">
            <h2 className="count-highlighed">80%</h2>
            <Link className="sub-text" to='#/'>Cheaper than UGC ads</Link>
          </div>
        </div>
      </div> */}
      <div className="margin-xl">
        <h1 className="headline">Boost your creativity with I made a song's powerful features</h1>
        <div className="cards-container">
          <div className="row">
            <GridCard
              title="Custom Song"
              content="Transform any text or personal message into a one-of-a-kind song with 'I Made a Song's AI-powered text-to-song generator. Craft unique melodies from your words and make every message memorable."
            />
            <GridCard
              title="Custom Music"
              content="Add a voice to your ads with over 130 natural-sounding AI voices, or craft your own unique narrative with our script-writing tool."
            />
          </div>

          <div className="row">
            <GridCard
              title="AI Lyric Writer"
              content="Effortlessly create meaningful lyrics for any genre or theme. From social media shout-outs to personal dedications, let AI help you craft compelling lyrics that make every song unforgettable."
            />
            <GridCard
              title="Custom Vocal Avatar"
              content="Design your own digital singer with 'I Made a Song' custom avatar feature! Choose a voice style, tone, and language to create a truly personal song experience with a unique vocal identity."
            />
            <GridCard
              title="Batch Song Creation"
              content="Generate multiple versions of your song in one go. Explore different genres, moods, and vocal styles, and find the one that resonates best. Perfect for testing and refining your musical ideas!"
            />
            <GridCard
              title="Mood-Based Song Generator"
              content="Create a song based on your current mood or emotion. Whether you're feeling happy, nostalgic, or relaxed, let our AI craft the perfect melody and lyrics to match your vibe."
            />
          </div>
        </div>
      </div>
      <DashboardSubscription />
    </div>
  );
};

export default HomePage;
