import React, { useState } from 'react';

function PricingPlans() {

  const [isYearly, setIsYearly] = useState(false);

  const plans = [
    {
      name: '1 Song Plan',
      yearlyPrice: 'Rs. 499',
      monthlyPrice: 'Rs. 499',
      features: [
        'Download 1 song',
        'Cannot be used Commercially',
        'No recurring charges monthly',
        '1 month validity',
      ],
      status: 'Active',
    },
    {
      name: '3 Songs Plan',
      yearlyPrice: 'Rs. 1,399',
      monthlyPrice: 'Rs. 1,399',
      features: [
        'Download 3 songs',
        'Cannot be used Commercially',
        'No recurring charges monthly',
        '2 months validity',
      ],
    },
    {
      name: '10 Songs Plan',
      yearlyPrice: 'Rs. 3,499',
      monthlyPrice: 'Rs. 3,499',
      features: [
        'Download 10 songs',
        'Cannot be used Commercially',
        'No recurring charges monthly',
        '3 months validity',
      ],
    },
    {
      name: '25 Songs Plan',
      yearlyPrice: 'Rs. 6,999',
      monthlyPrice: 'Rs. 6,999',
      features: [
        'Download 25 songs',
        'Cannot be used Commercially',
        'No recurring charges monthly',
        '6 months validity',
      ],
    },
  ];

  return (
    <div className="min-h-screen  text-white flex flex-col items-center p-8 main-content">
      <h2 className="text-5xl font-bold mb-10 mt-20">Choose the plan that works for you.</h2>
      <div className="flex gap-4 mb-12">
        {/* <button
          className={`px-4 py-2 rounded ${isYearly ? 'bg-gray-700' : 'bg-white text-black'}`}
          onClick={() => setIsYearly(false)}
        >
        Monthly billing  
        </button> */}
        {/* <button
          className={`px-4 py-2 rounded ${isYearly ? 'bg-white text-black' : 'bg-gray-700'}`}
          onClick={() => setIsYearly(true)}
        >
          Yearly billing
        </button> */}
      </div>
      <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
        {plans.map((plan, index) => (
          <div
            key={index}
            className=" p-8 rounded-lg flex flex-col items-center"
          >
            <h3 className="text-2xl  text-transparent font-extrabold mb-4 bg-clip-text bg-gradient-to-r from-green-500 via-teal-500 to-pink-500 ">{plan.name}</h3>
            <p className="text-2xl font-bold mb-4">{isYearly ? plan.yearlyPrice : plan.monthlyPrice}</p>
            <button className="mb-4 px-6 py-4 rounded-full button-bg-color w-full">
              {plan.status ? plan.status : 'Subscribe'}
            </button>
            <ul className="text-sm space-y-2">
              {plan.features.map((feature, i) => (
                <li key={i} className="flex items-start">
                  <span className="mr-2">•</span> {feature}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
}

export default PricingPlans;
