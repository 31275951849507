import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BrandIconImage from "../../components/BrandIconImage";
import { signUpUser } from "../../redux/ReduxSlices/UserSlice";
import { countryData } from "../../utils/countryCode";
import logoVideo from "../../assets/gif/brandImg.mp4";

const SignUpPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [countryCode,setCountryCode] = useState("+91")

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    username: "",
    country_code: "+91",
    mobile: "",
    password: "",
    confirm_password: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validatePassword = (password) => {
    const passwordRegex =
      /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,20}$/;
    return passwordRegex.test(password);
  };

  const handleCountryCodeChange = (e) => {
    setFormData({
      ...formData,
      country_code: e.target.value,
    });
  };


  const handleSubmit = (e) => {
    e.preventDefault();

    if (formData.password !== formData.confirm_password) {
      toast.error("Passwords do not match!");
      return;
    }

    if (!validatePassword(formData.password)) {
      toast.error(
        "Password must contain at least 8 characters, one uppercase letter, one number, and one special character."
      );
      return;
    }
    dispatch(signUpUser(formData))
      .unwrap()
      .then(() => {
        toast.success("User registered successfully!");
        navigate("/signin");
      })
      .catch((error) => {
        console.log(error, "error for signup");
        const errorMessage = error || "Signup failed!";
        console.log("errorMessage", errorMessage);
        toast.error(errorMessage || " sign up failed");
      });
  };

  const calculatePasswordStrength = (password) => {
    let strength = 0;

    if (password.length >= 8 && password.length === 20) strength += 1;
    if (/[A-Z]/.test(password)) strength += 1;
    if (/\d/.test(password)) strength += 1;
    if (/[!@#$%^&*]/.test(password)) strength += 1;

    return strength;
  };

  const getStrengthLabelAndColor = (strength) => {
    switch (strength) {
      case 1:
        return { label: "Weak", color: "bg-red-500" };
      case 2:
        return { label: "good", color: "bg-yellow-400" };
      case 3:
        return { label: "Excellent", color: "bg-green-400" };

      default:
        return { label: "", color: "bg-gray-300" };
    }
  };
  const strengthData = getStrengthLabelAndColor(
    calculatePasswordStrength(formData.password)
  );

  return (
    <div className="w-full min-h-screen text-gray-900 flex relative top-8 bg-black">
      <div className=" m-0   sm:rounded-lg flex justify-center flex-1">
        <div className="flex-1  text-center hidden lg:flex justify-center">
          <div className=" w-3/4 bg-contain rounded flex items-center flex-col justify-center ">
            <div className="mb-6 ">
              <video
                className="object-cover rounded-lg"
                autoPlay
                playsInline
                loop
                muted
              >
                <source src={logoVideo} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
        <div className="lg:w-1/2 w-full flex items-center justify-center p-5 right-side-bg">
          <div className="flex mt-5 flex-col items-center justify-center">
            <h1 className="text-3xl xl:text-3xl font-extrabold text-black">
              Sign Up
            </h1>
            <form onSubmit={handleSubmit}>
              <div className="flex-1 mt-4">
                <div className="flex flex-col items-center">
                  <div className="w-full">
                    <div className="w-full">
                      <div className="grid gap-3 md:grid-cols-2 mt-5">
                        <div className="w-full">
                          <input
                            required
                            type="text"
                            placeholder="Your Name"
                            name="first_name"
                            value={formData.first_name}
                            onChange={handleInputChange}
                            className="px-6 py-3 mt-2 h-12 w-full font-medium rounded-lg bg-gray-100 border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-"
                          />
                        </div>
                        <div>
                          <input
                            type="text"
                            required
                            placeholder="Last Name"
                            name="last_name"
                            value={formData.last_name}
                            onChange={handleInputChange}
                            className="px-6 py-3 mt-2 h-12 w-full font-medium rounded-lg bg-gray-100 border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
                          />
                        </div>
                      </div>
                      <input
                        required
                        className="w-full px-6 py-3 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5"
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        placeholder="Email"
                      />
                      <div>
                        <input
                          type="text"
                          required
                          placeholder="Username"
                          name="username"
                          value={formData.username}
                          onChange={handleInputChange}
                          className="w-full px-6 py-3 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5"
                        />
                      </div>
                      <div className="w-full">
                        <div className="flex">
                          <select
                            required
                            value={formData.country_code}
                            onChange={handleCountryCodeChange}
                            className="px-6 py-3 mt-5 h-12 w-1/3 font-medium rounded-lg border-gray-200 bg-gray-100 text-sm focus:outline-none focus:border-gray-400 focus:bg-white"
                          >
                            <option value="" disabled>
                              Select country code
                            </option>
                            {countryData.map((country, index) => (
                              <option key={index} value={country.code}>
                                {country.name} ({country.code})
                              </option>
                            ))}
                          </select>
                          <input
                            type="number"
                            required
                            name="mobile"
                            placeholder="Mobile No"
                            value={formData.mobile}
                            onChange={handleInputChange}
                            className="w-2/3 px-6 py-3 mt-5 h-12 font-medium rounded-lg border-gray-200 bg-gray-100 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white ml-5"
                          />
                        </div>
                      </div>
                      <input
                        className="w-full px-6 py-3 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5"
                        type="password"
                        required
                        name="password"
                        value={formData.password}
                        onChange={handleInputChange}
                        placeholder="Password"
                      />
                      <div className="mt-2">
                        <div className="">
                          <div
                            className={`${strengthData.color} h-1 rounded-md transition-all duration-300`}
                            style={{
                              width: `${
                                (calculatePasswordStrength(formData.password) /
                                  4) *
                                100
                              }%`,
                            }}
                          />
                        </div>
                        <p className="text-sm text-gray-600">
                          {strengthData.label}
                        </p>
                      </div>
                      <input
                        className="w-full px-6 py-3 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5"
                        type="password"
                        name="confirm_password"
                        value={formData.confirm_password}
                        onChange={handleInputChange}
                        placeholder="Confirm Password"
                      />
                    </div>
                    <button className="mt-10 tracking-wide font-semibold button-bg-color text-gray-100 w-full py-4 rounded-lg hover:bg-indigo-700 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none">
                      <span className="ml-3">Continue</span>
                    </button>
                  </div>
                  <div className="leading px-2 py-4 text-center text-sm text-gray-600 font-medium">
                    <p className="items-center text-gray-400">
                      Already have an account?{" "}
                      <span
                        className="underline hover:text-blue-500"
                        onClick={() => navigate("/signin")}
                      >
                        Sign In
                      </span>
                    </p>
                  </div>
                  <hr className="mt-6 lg:w-1/2" />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default SignUpPage;
