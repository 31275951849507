import React from 'react';
import "../styles/homePage.css";


const GridCard = ({ title, content }) => {
    return (
        <div className="grid-card">
            <h3 class="header">{title}</h3>
            <p className="card-sub-text mt-4">{content}</p>
        </div>
    );
};

export default GridCard;

