//  Api Service

const BASE_URL = process.env.REACT_APP_STAGE_URL;

const environments = {
  stage: BASE_URL,
  production: "",
};

const activeEnvironment = environments.stage;

const apiUrl = {
  login: activeEnvironment + "/login",
  UserSignUp: activeEnvironment + "/signup",
  getUserDetails: activeEnvironment + "/get_user_details/",
  creditBalance: activeEnvironment + "/credit_balance/",
  deductCredit: activeEnvironment + "/deduct_credit/"
};

export default apiUrl;
