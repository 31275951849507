import React from 'react';

const HowToUsePlatform = ({ onClose }) => {
  return (
    <div className="p-6 max-w-4xl h-66 mx-auto bg-gradient-to-r from-green-100 via-teal-100 to-pink-100 rounded-lg  mt-5">
    <div className='shadow-xl  shadow-teal-300 p-5'>
    <div className='relative'>
     <button
        onClick={onClose}
        className="absolute right-4 text-white hover:text-gray-900 bg-red-600 rounded-md"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
     </div>

      <h1 className="text-xl font-bold mb-4 mt-4 ">How to Use the "I Made a Song" Platform</h1>

      <div className="mb-6 ">
        <h2 className="modal__title text-md font-semibold mb-2">1. Enter Your Prompt</h2>
        <p className="modal__text text-gray-900">
          In the <span className="font-semibold">Prompt Textbox</span>, type a description of the type of song you want to generate. Be as specific as possible!
        </p>
        <p className="modal__text text-gray-900">
          For example, you can describe the genre, mood (happy, sad, energetic), and themes (love, adventure, nature).
        </p>
        <div className="bg-gray-100 p-4 mt-2 rounded-md">
          <span className="italic text-gray-900">
            Example Prompt: “Create a peaceful acoustic song about nature with soft guitar and soothing vocals.”
          </span>
        </div>
      </div>

      <div className="mb-6">
        <h2 className="modal__title text-md font-semibold mb-2">2. Click on “Generate”</h2>
        <p className="modal__text text-gray-900">
          After entering your prompt, click the <span className="font-semibold">Generate</span> button. The platform will process your request and create two unique songs based on your description.
        </p>
        <p className="modal__text text-gray-900">
          After the songs are generated, you will be presented with two different versions of songs based on your prompt.
        </p>
        <ul className="list-disc pl-5 mt-2 text-gray-900">
          <li>Use the Play buttons to listen to both tracks.</li>
          <li>You can listen to one or both songs to see which one fits your vision.</li>
        </ul>
      </div>

      <div>
        <h2 className="modal__title text-md font-semibold mb-2">3. View Your Song List and Library</h2>
        <p className="modal__text text-gray-900">
          To manage your songs, go to the <span className="font-semibold">Song List</span> or <span className="font-semibold">Library</span> section, where you can view all the songs you’ve generated.
        </p>
        <p className="modal__text text-gray-900">
          Here, you can see a list of your saved songs, listen to them again, or download them anytime. You can organize your songs by genre, mood, or any tags you may have added, making it easy to find the perfect track for your needs.
        </p>
      </div>
    </div>
    </div>
  );
};

export default HowToUsePlatform;
