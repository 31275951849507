import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import "../styles/navbar.css"; // Ensure this path is correct
import brandImage from "../assets/dashboard/brand_img.png";
import { FaUserCircle } from 'react-icons/fa';

const Navbar = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  const checkLoginStatus = () => {
    const token = localStorage.getItem("token");
    setIsLoggedIn(!!token);
  };

  useEffect(() => {
    checkLoginStatus();

    const handleStorageChange = (event) => {
      if (event.key === 'token') {
        checkLoginStatus();
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  // Handle logout
  const handleLogout = () => {
    localStorage.removeItem("token");
    setIsLoggedIn(false);
    navigate("/signin");
  };

  return (
    <nav className="flex items-center justify-between py-1 px-6 bg-black fixed w-full z-10">
      <div className="flex items-center">
        <Link to="/">
          <img src={brandImage} alt="I Made a Song" className="h-16 w-24 cursor-pointer" />
        </Link>
      </div>

      {/* <div className="flex gap-14 items-center">
        <Link to="/default-page" className="text-white text-[1.08rem] hover:text-[#00aaff]">Solutions</Link>
        <Link to="/default-page" className="text-white text-[1.08rem] hover:text-[#00aaff]">Resources</Link>
        <Link to="/" className="text-white text-[1.08rem] hover:text-[#00aaff]">Company</Link>
        <Link to="/default-page" className="text-white text-[1.08rem] hover:text-[#00aaff]">Pricing</Link>
        <Link to="/default-page" className="text-white text-[1.08rem] hover:text-[#00aaff]">API</Link>
      </div> */}

      <div className="flex gap-4">
        {isLoggedIn ? (
          <>
            {/* <Link to="/get-started" className="text-white border border-white py-2 px-4 rounded hover:text-[#00aaff] hover:border-[#00aaff]">Make Song</Link> */}
            <button onClick={handleLogout} className="text-white border border-white py-2 px-4 rounded hover:text-[#00aaff] hover:border-[#00aaff]">Log Out</button>
            <Link to="/dashboard" className="text-white">
              <FaUserCircle size={36} className="mt-1" />
            </Link>
          </>
        ) : (
          <>
            <Link to="/signin" className="text-white border border-white py-2 px-4 rounded hover:text-[#00aaff] hover:border-[#00aaff]">Sign In</Link>
            {/* <Link to="/get-started" className="text-white border border-white py-2 px-4 rounded hover:text-[#00aaff] hover:border-[#00aaff]">Make Song</Link> */}
          </>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
