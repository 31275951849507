
import React, { useState } from 'react';
import { AiFillPlayCircle, AiFillPauseCircle } from 'react-icons/ai';

import { IconContext } from 'react-icons';


export default function SongList({songs}) {
  console.log("songs",songs)
  const [currentAudio, setCurrentAudio] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [activeSongId, setActiveSongId] = useState(null);


  const handlePlay = (audioUrl, songId) => {

    if (currentAudio) {
      currentAudio.pause();
    }


    if (activeSongId === songId) {
      setIsPlaying(false);
      setActiveSongId(null);
      return;
    }


    const audio = new Audio(audioUrl);
    audio.play().catch((error) => console.error("Error playing audio:", error));
    setCurrentAudio(audio);
    setIsPlaying(true);
    setActiveSongId(songId);


    audio.addEventListener('ended', () => {
      setIsPlaying(false);
      setActiveSongId(null);
    });
  };

  return (
    <div className="min-h-screen py-8 ">
      {/**/}
      <div className="flex items-center justify-center gap-5">
        {songs.map((song) => (
          <div
            key={song.id}
            className="bg-white rounded-lg shadow-md p-4 flex flex-col items-center justify-center w-64"
          >
            <img
              src={song.image_url}
              alt={song.title}
              className="w-full h-40 object-cover rounded-lg mb-4"
            />
            <h3 className="text-lg font-medium text-gray-700 mb-2">{song.title}</h3>
            <p className="text-gray-500 text-sm mb-4">{song.model_name}</p>
            <div className="flex items-center justify-center">

              {activeSongId === song.id && isPlaying ? (
                <button className="playButton" onClick={() => handlePlay(song.audio_url, song.id)}>
                  <IconContext.Provider value={{ size: "3em", color: "#27AE60" }}>
                    <AiFillPauseCircle />
                  </IconContext.Provider>
                </button>
              ) : (
                <button className="playButton" onClick={() => handlePlay(song.audio_url, song.id)}>
                  <IconContext.Provider value={{ size: "3em", color: "#27AE60" }}>
                    <AiFillPlayCircle />
                  </IconContext.Provider>
                </button>
              )}

            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

