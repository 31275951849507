import React from "react";
import brandLogo from "../assets/dashboard/brand_img.webp";
import {
  FaLinkedin,
  FaInstagram,
  FaTiktok,
  FaTwitter,
  FaYoutube,
  FaRobot,
  FaSnapchatGhost,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="text-white py-8 mb-0">
      <div className="container mx-auto grid grid-cols-1 sm:grid-cols-8 gap-10 mb-4">
        {/* Branding Section */}
        <div className="col-span-4 sm:col-span-4">
          <div className="flex items-center space-x-2">
            <img src={brandLogo} alt="Logo" style={{ height: "100px" }} />
          </div>
          <p className="text-lg font-bold">Digikore Studios Private Limited</p>
          <p className="mt-4">
            410/1, Growel House, Mumbai Pune Road,
          </p>
          <p>
            Dapodi, Pune, Pune, MH, 411012.
          </p>
          <p>Email: <a href="mailto: support@imadeasong.com" className="text-blue-600">support@imadeasong.com</a></p>
          {/* <div className="flex space-x-4 mt-4">
            <a href="#">
              <FaLinkedin className="h-6 w-6" aria-label="LinkedIn" />
            </a>
            <a href="#">
              <FaInstagram className="h-6 w-6" aria-label="Instagram" />
            </a>
            <a href="#">
              <FaTiktok className="h-6 w-6" aria-label="TikTok" />
            </a>
            <a href="#">
              <FaTwitter className="h-6 w-6" aria-label="Twitter" />
            </a>
            <a href="#">
              <FaYoutube className="h-6 w-6" aria-label="YouTube" />
            </a>
          </div>
          <div className="flex mt-4 space-x-4">
            <a href="#">
              <FaSnapchatGhost className="h-6 w-6" aria-label="Snapchat" />
            </a>
            <a href="#">
              <FaTiktok className="h-6 w-6" aria-label="TikTok App Center" />
            </a>
            <a href="#">
              <FaRobot className="h-6 w-6" aria-label="AI Grant" />
            </a>
          </div>
          <div className="mt-4">
            <span className="bg-red-500 text-white py-1 px-3 rounded-full inline-block">
              #1 Product of the Day
            </span>
          </div> */}
        </div>

        {/* Features Section */}
        <div className="flex justify-start">
          {/* <div>
            <h3 className="text-lg font-semibold text-gray-500">Features</h3>
            <ul className="mt-4 space-y-2">
              <li>
                <a href="#" className="hover:underline text-sm">
                  All Features
                </a>
              </li>
              <li>
                <a href="#" className="hover:underline text-sm">
                  URL to Video
                </a>
              </li>
              <li>
                <a href="#" className="hover:underline text-sm">
                  AI Avatar
                </a>
              </li>
              <li>
                <a href="#" className="hover:underline text-sm">
                  BYOA Avatar
                </a>
              </li>
              <li>
                <a href="#" className="hover:underline text-sm">
                  DYOA Avatar
                </a>
              </li>
              <li>
                <a href="#" className="hover:underline text-sm">
                  Batch Mode
                </a>
              </li>
              <li>
                <a href="#" className="hover:underline text-sm">
                  Text to Speech
                </a>
              </li>
              <li>
                <a href="#" className="hover:underline text-sm">
                  AI Script Writer
                </a>
              </li>
            </ul>
          </div> */}
        </div>

        {/* Use Cases Section */}
        <div className="flex justify-center">
          {/* <div>
            <h3 className="text-lg font-semibold text-gray-500">Use Cases</h3>
            <div className="flex justify-center gap-10 mt-4">
              <div>
                <ul className="space-y-2">
                  <li>
                    <a href="#" className="hover:underline text-sm">
                      eCommerce
                    </a>
                  </li>
                  <li>
                    <a href="#" className="hover:underline text-sm">
                      Apps
                    </a>
                  </li>
                  <li>
                    <a href="#" className="hover:underline text-sm">
                      Games
                    </a>
                  </li>
                  <li>
                    <a href="#" className="hover:underline text-sm">
                      DTC Brands
                    </a>
                  </li>
                  <li>
                    <a href="#" className="hover:underline text-sm">
                      Agencies
                    </a>
                  </li>
                  <li>
                    <a href="#" className="hover:underline text-sm">
                      TikTok
                    </a>
                  </li>
                  <li>
                    <a href="#" className="hover:underline text-sm">
                      Real Estate
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
        </div>

        <div className="flex justify-center">
          {/* <div>
            <h3 className="text-lg font-semibold text-gray-500">Social</h3>
            <div className="flex justify-center gap-10 mt-4">
              <ul className="space-y-2">
                <li>
                  <a href="#" className="hover:underline text-sm">
                    Instagram
                  </a>
                </li>
                <li>
                  <a href="#" className="hover:underline text-sm">
                    Facebook
                  </a>
                </li>
                <li>
                  <a href="#" className="hover:underline text-sm">
                    YouTube
                  </a>
                </li>
                <li>
                  <a href="#" className="hover:underline text-sm">
                    Create AI Avatar
                  </a>
                </li>
                <li>
                  <a href="#" className="hover:underline text-sm">
                    OTT & CTV
                  </a>
                </li>
                <li>
                  <a href="#" className="hover:underline text-sm">
                    Lead Generation
                  </a>
                </li>
              </ul>

            </div>
          </div> */}
        </div>

        {/* Company Section */}
        <div className="flex justify-end">
          {/* <div>
            <h3 className="text-lg font-semibold text-gray-500">Company</h3>
            <ul className="mt-4 space-y-2">
              <li>
                <a href="#" className="hover:underline text-sm">
                  Blog
                </a>
              </li>
              <li>
                <a href="#" className="hover:underline text-sm">
                  Pricing
                </a>
              </li>
              <li>
                <a href="#" className="hover:underline text-sm">
                  About Us
                </a>
              </li>
              <li>
                <a href="#" className="hover:underline text-sm">
                  Ad Examples
                </a>
              </li>
              <li>
                <a href="#" className="hover:underline text-sm">
                  Case Studies
                </a>
              </li>
              <li>
                <a href="#" className="hover:underline text-sm">
                  Contact Us
                </a>
              </li>
              <li>
                <a href="#" className="hover:underline text-sm">
                  AI Ethics
                </a>
              </li>
            </ul>
          </div> */}
        </div>
      </div>

      {/* Footer Bottom */}
      <div className="container mx-auto flex justify-between items-center border-t border-gray-700 pt-3">
        <p className="text-gray-500">I Made a Song © Copyright 2024</p>
        <div className="flex space-x-[60px]">
          <a href="/terms-of-service" className="text-gray-500 hover:underline">
            Terms and Services
          </a>
          <Link to={"/privacy-policy"} className="text-gray-500 hover:underline">
            Privacy Policy
          </Link>
          <Link to={"/cancellation-policy"} className="text-gray-500 hover:underline">
            Cancellation and refund policy
          </Link>
          <Link to={"/about-us"} className=" text-gray-500 hover:underline">
            About Us
          </Link>
        </div>
        <div className="ml-4">
          <a href="#" className="p-2 rounded-full">
            {/* Chat Icon */}
            {/* <img src="chat_icon.svg" alt="Chat" className="h-6" /> */}
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
