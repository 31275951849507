import React, { useState } from 'react';
import GetAllVoice from '../VoiceCreation/GetAllVoice.jsx';
import GetStartedPage from '../VoiceCreation/GetStartedPage.jsx';
import Sidebar from './Sidebar.jsx';
import UpgradePlane from "../upgrade_Plans/UpgradePlan.jsx"


function DashboardPage() {
    const [activeContent, setActiveContent] = useState('create');

    const renderContent = () => {
        switch (activeContent) {

            case 'create':
                return <GetStartedPage />
            case 'library':
                return <GetAllVoice />
            case 'upgrade':
                return <UpgradePlane />
            default:
                return <GetStartedPage />

        }
    };

    return (
        <div className="app">
            <Sidebar setActiveContent={setActiveContent} activeContent={activeContent} />
            {renderContent()}
        </div>
    )
}

export default DashboardPage;
