import React from 'react'
import brandImage from '.././assets/dashboard/brand_img.png'

function BrandIconImage() {
    return (
        <div className="mb-5">
            <img src={brandImage} alt="Brand-Image" className="h-40 w-40" />
        </div>
    )
}

export default BrandIconImage
